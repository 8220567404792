import { Injectable } from "@angular/core";
// import * as amplifyStore from "amplify-store";

@Injectable({
  providedIn: "root"
})
export class AmplifyService {
  constructor() { }
  SetStorage(key: string, value: any) {
    // amplifyStore(key, value);
    localStorage.setItem(key, JSON.stringify(value));
  }

  GetStorage(key: string) {
    // return amplifyStore(key);
    return JSON.parse(localStorage.getItem(key));
  }

  ClearStorage(key: string) {
    // amplifyStore(key, null);
    localStorage.removeItem(key);
  }
  ClearAll() {
    const storageArray = ["userList", "userList_PageNo"];
    for (const storageItem of storageArray) {
      this.ClearStorage(storageItem);
    }
  }
}
